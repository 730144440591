import React from 'react';
import { Input, message, Spin } from 'antd';
import './index.less';

const IconFont = Loader.loadBaseComponent('IconFont');
@Decorator.businessProvider('flowPerson', 'residentPerson', 'user')
class UploadInput extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      imgUrl: props.imgUrl,
      extraImgurl: props.extraImgurl,
      allImgurl: props.allImgurl,
      capShow: false,
      val: props.val,
      extraVal: props.extraVal,
      allVal: props.allVal,
      feature: '',
      file: '',
      loading: false
    };
  }
  

  onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    let df = e.dataTransfer.items[0];
    var file = df.getAsFile();
    this.setState({
      loading: true
    });
    this.upDataImg(file);
  };

  upDataImg = async file => {
    this.props.changeImg && this.props.changeImg(true);
    if (file.size > 5 * 1024 * 1024) {
      return message.warn('图片大小不能超过5M!');
    }
    if (!file.size) {
      return message.warn('图片文件错误!');
    }
    let newFile = file;
    if (file.type !== 'image/svg+xml') {
      newFile = await Utils.imageCompress(file);
    }
    const formData = new FormData();
    formData.append('file', newFile);
    Service.face
      .uploadImg(formData)
      .then(result => {
        this.getImgFeature(result.data.url, 1);
      })
      .catch(e => {
        message.error('图片上传失败');
        this.setState({
          loading: false
        });
      });
  };
  //提取图片特征
  getImgFeature = (imgUrl, type) => {
    const { getFeature, deleteImg, changeImg } = this.props;
    //type为1是url上传为2是base64上传
    let options = {
      score: 0.6
    };
    let concatImgurl = imgUrl;
    if (type === 2) {
      options.base64 = imgUrl;
    } else {
      options.url = imgUrl;
    }
    Service.face
      .getFeature(options)
      .then(res => {
        if (res.data.list.length > 0 && res.data.list[0].feature) {
          let feature = res.data.list[0].feature;
          this.setState({
            feature: feature,
            loading: false
          });
          getFeature && getFeature(feature);
          this.updateImg(concatImgurl);
        } else {
          this.fileInput.current.input.value = '';
          message.warn('提取图片特征失败,请重新上传');
          this.setState({
            capShow: false,
            loading: false
          });
          deleteImg && deleteImg(true);
        }
      })
      .catch(() => {
        message.error('提取图片特征失败,请重新上传');
        this.setState({
          loading: false
        });
      });
    changeImg && changeImg(false);
  };
  /**更新图片显示的state */
  updateImg = url => {
    let { Loadtype,setImage } = this.props;
    if (Loadtype == 1) {
      this.setState({
        imgUrl: url
      });
    }
    if (Loadtype == 2) {
      this.setState({
        extraImgurl: url
      });
    }
    if (Loadtype == 3) {
      this.setState({
        allImgurl: url
      });
    }
    setImage && setImage(url)
  };
  fileInputChange = e => {
    let file = e.target.files[0];
    this.setState({
      loading: true
    });
    this.upDataImg(file);
  };
  onDragOver = e => {
    e.preventDefault();
  };

  /**处理关键字搜索的问题 */
  onChange = e => {
    const { Loadtype, residentPerson, flowPerson, isCover,setKeywords } = this.props;
    if (Loadtype == 1) {
      this.setState({
        val: e.target.value
      });
    }
    if (Loadtype == 2) {
      this.setState({
        extraVal: e.target.value
      });
    }
    if (Loadtype == 3) {
      this.setState({
        allVal: e.target.value
      });
    }
    setKeywords && setKeywords(e.target.value)
    if (isCover) {
      residentPerson.editSearchData({ keywords: e.target.value }, Loadtype);
    } else {
      flowPerson.editSearchData({ keywords: e.target.value }, Loadtype);
    }
  };
  deleteImg = () => {
    const { Loadtype, deleteImg,setImage,setKeywords } = this.props;
    if (Loadtype == 1) {
      this.setState({
        imgUrl: '',
        val: ''
      });
    }
    if (Loadtype == 2) {
      this.setState({
        extraImgurl: '',
        extraVal: ''
      });
    }
    if (Loadtype == 3) {
      this.setState({
        allImgurl: '',
        allVal: ''
      });
    }
    setImage && setImage('')
    setKeywords && setKeywords('')
    this.fileInput.current.state.value = null
    deleteImg && deleteImg();
  };
  edit = () => {
    this.setState({
      capShow: true
    });
  };
  captureCb = src => {
    const { Loadtype, residentPerson, flowPerson, isCover } = this.props;
    if (isCover) {
      residentPerson.editImgUrl(src, Loadtype);
    } else {
      flowPerson.editImgUrl(src, Loadtype);
    }
    this.close();
    this.getImgFeature(src, 2);
  };
  search = () => {
    let { feature, val } = this.state;
    this.props.search && this.props.search(val, feature);
    this.close();
  };
  close = () => {
    this.setState({
      capShow: false
    });
  };
  render() {
    let { Loadtype, isCover,placeholder } = this.props;
    let { loading, imgUrl, extraImgurl, allImgurl, val, extraVal, allVal } = this.state;
    let RealImgurl = Loadtype == 1 ? imgUrl : Loadtype == 2 ? extraImgurl : allImgurl;
    let RealVal = Loadtype == 1 ? val : Loadtype == 2 ? extraVal : allVal;
    return (
      <div className="upload-input-view">
        <div className="upload-input-box">
          <Input
            onDrop={this.onDrop}
            onPressEnter={this.search}
            onDragLeave={this.onDragLeave}
            onDragOver={this.onDragOver}
            onChange={this.onChange}
            value={RealVal}
            style={RealImgurl || loading ? { paddingLeft: '33px' } : {}}
            className="upload-input"
            placeholder={placeholder || (isCover ? '请输入姓名或身份证搜索' : '请输入人员相关属性搜索')}
          />
          <div className="delete-pic-input">
            {(RealVal || RealImgurl) && <IconFont onClick={this.deleteImg} type={'icon-S_Edit_Clear'} theme="outlined" style={{ fontSize: '16px' }} />}
          </div>
          <div className="camera-btn">
            <label>
              <Input type="file" ref={this.fileInput} accept="image/*" onChange={this.fileInputChange} style={{ visibility: 'hidden', position: 'fixed' }} />
              {!loading && !RealImgurl && !RealVal && (
                <IconFont
                  type={'icon-S_Edit_ImgSearch'}
                  theme="outlined"
                  style={{
                    fontSize: '18px',
                    color: '#8899BB',
                    marginTop: '0px',
                    marginLeft: '3px'
                  }}
                />
              )}
            </label>
          </div>
          <div className="search-btn" onClick={this.search}>
            <IconFont type={'icon-S_Edit_Search'} theme="outlined" />
          </div>
          {RealImgurl ? (
            <div className="img-view">
              <div className="img-box">
                <img src={RealImgurl} />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {loading && (
                <div className="img-view" style={{ marginLeft: 3, marginTop: 2 }}>
                  <div className="img-box">
                    <Spin size="small" spinning={loading} />
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default UploadInput;
